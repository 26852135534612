import { IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi from "api/SupernovaApi";
import { EventType, Webhook, WebhookLogEntry } from "api/SupernovaApi/models";
import PageWrapper from "components/PageWrapper";
import { format } from "date-fns";
import useToaster from "framework/Toaster/useToaster";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SubscribeModal from "../SubscribeModal";
import TestModal from "../TestModal";

interface Props {
}

const Abonnement = (props: Props) => {

    let { id } = useParams();

    const toaster = useToaster();

    const [webhook, setWebhook] = useState<Webhook>();

    const getWebhook = async () => {
        const reponse = await SupernovaApi.webhooks.get(id);
        setWebhook(reponse);
    }

    const getLog = async () => {
        const reponse = await SupernovaApi.webhooks.getLog(id, false);
        setMessages(reponse);
    }

    const [messages, setMessages] = useState<WebhookLogEntry[]>([]);

    useEffect(() => {
        getWebhook();
        getLog();
    }, [id]);

    const [eventTypes, setEventTypes] = useState<EventType[]>([]);

    const loadEventTypes = async () => {
        const et = await SupernovaApi.eventTypes.getEventTypes();
        setEventTypes(et);
    };

    useEffect(() => {
        loadEventTypes();
    }, []);

    const [showAddAbonnementModal, setShowAddAbonnementModal] = useState<boolean>(false);
    const add = () => {
        setShowAddAbonnementModal(true);
    }

    const handleSubscribeClose = (): void => {
        setShowAddAbonnementModal(false);
        getWebhook();
    }

    const unsubscribeFromEventType = async (eventType: EventType) => {
        const result = await SupernovaApi.webhooks.unsubscribeToEventType(webhook?.id, eventType.id);
        if (result.success) {
            toaster.success('Abonnement fjernet for hendelse: ' + eventType.displayName);
        } else {
            toaster.error('Noe gikk galt, ' + result.error);
        }
        getWebhook();
    };

    const [showTestModal, setShowTestModal] = useState<boolean>(false);
    const [selectedEventType, setSelectedEventType] = useState<EventType>();
    const testEvent = (eventType: EventType): void => {
        setShowTestModal(true);
        setSelectedEventType(eventType);
    }

    const handleTestClose = (): void => {
        setShowTestModal(false);
    }

    return (
        <PageWrapper
            title={`Abonnement for ${webhook?.name}`}
            rightContent={
                <IconButton icon="plus" variant="primary" onClick={add}>Flere varsler</IconButton>
            }
        >
            <h4>Hendelser</h4>
            {webhook?.subscribedToEventTypeIds?.length === 0 && <p>Ingen hendelser er abonnert på</p>}
            <Table className="tsTable" hover>
                <tbody>
                    {eventTypes.filter(o => webhook?.subscribedToEventTypeIds?.includes(o.id)).map((eventType, index) => (
                        <tr key={eventType.id}>
                            <td>
                                <strong title={eventType.description} style={{ fontWeight: 600 }}>{eventType.displayName}</strong> - {eventType.source}<br />
                            </td>
                            <td className="fitContent">
                                <IconButton
                                    icon="send"
                                    title="Test"
                                    variant="outline-primary"
                                    onClick={() => testEvent(eventType)}
                                >Test</IconButton>
                            </td>
                            <td className="fitContent">
                                <IconButton
                                    icon="trash"
                                    title="Avmeld"
                                    variant="outline-primary"
                                    onClick={() => unsubscribeFromEventType(eventType)}
                                >Fjern varsel</IconButton>
                            </td>
                        </tr>
                    )
                    )}
                </tbody>
            </Table>
            <h4 className="mt-5">Siste meldinger mottatt</h4>
            {messages.length === 0 && <p>Ingen meldinger er sendt til denne webhook'en enda</p>}
            <Table className="tsTable" hover>
                <tbody>
                    {messages.map((message, index) => (
                        <tr key={message.eventId}>
                            <td>
                                {message.initialDeliveryAttemptedAt ? format(
                                    message.initialDeliveryAttemptedAt,
                                    'dd.MM.yyyy HH:mm:ss'
                                ) : ''}
                            </td>
                            <td>
                                <strong style={{ fontWeight: 600 }}>{message.event}</strong> - {message.source}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <SubscribeModal show={showAddAbonnementModal} webhook={webhook} onClose={handleSubscribeClose} />
            <TestModal show={showTestModal} webhook={webhook} eventType={selectedEventType} onClose={handleTestClose} />
        </PageWrapper>

    );
}

export default Abonnement;